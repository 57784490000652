import logo from "./assets/logo.png";
import header from "./assets/header.png";
// import header2 from "./assets/header2.jpg";
// import header3 from "./assets/header3.png";
import feed1 from "./assets/feed1.jpg";
import feed2 from "./assets/feed2.jpg";
import feed3 from "./assets/feed3.jpg";
import feed4 from "./assets/feed4.jpg";
import feed5 from "./assets/feed5.jpg";
import reed1 from "./assets/reed1.jpg";
import Modal from "react-modal";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Gracias from "./Gracias";
import { Link } from "react-scroll";
import { useState, useEffect, useRef } from "react";
import { createGlobalStyle, keyframes } from "styled-components";
import axios from "axios";
import PhotoGallery from "react-photo-gallery";
import {
  FaInstagram,
  FaFacebook,
  FaWhatsapp,
  FaStar,
  FaTimes,
  FaPhoneAlt,
} from "react-icons/fa";
import styled from "styled-components";

const slideDown = keyframes`
  from {
    transform: translateY(-250px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
 body {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
    margin: 0; /* Agrega esta línea */
    padding: 0; /* Agrega esta línea */
  }
  body::-webkit-scrollbar {
    width: 0px; /* Chrome, Safari, Edge */
    background: transparent; /* Chrome, Safari, Edge */
  }
  .slideDown {
    animation: 0.5s ${slideDown} ease-out;
  }
  .slideFromRight {
    animation: 1s ${slideFromRight} ease-out;
  }
  .slideUp1 {
    animation: 1s ${slideDown} ease-out;
  }
  .slideUp2 {
    animation: 2s ${slideDown} ease-out;
  }
`;

const StarBackground = () => {
  const stars = [];
  for (let i = 0; i < 200; i++) {
    const style = {
      zIndex: 2,
      left: `${Math.random() * 100}vw`,
      top: `${Math.random() * 100}vh`,
      animationDuration: `${Math.random() * 3 + 2}s`,
      animationDelay: `${Math.random() * 2}s`,
    };
    stars.push(<div key={i} className="star" style={style}></div>);
  }
  return <div className="stars">{stars}</div>;
};

const GlobalStarStyle = createGlobalStyle`
  .star {
    position: fixed;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: yellow;
    animation: float 5s infinite ease-in-out;
  }

  @keyframes float {
    from {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0px);
    }
  }

  .star:nth-child(odd) {
    width: 1px;
    height: 1px;
  }
`;

const Header1 = styled.div`
  /* Oculta el header cuando el ancho del viewport es menor a 768px */
  @media (max-width: 820px) {
    display: none;
  }
`;

const StyledText = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color: purple; // Un color de texto más claro
  font-family: "Roboto", sans-serif; // La fuente importada de Google Fonts
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); // Ajusta estos valores para cambiar la sombra
  letter-spacing: 0.05em; // Espaciado de letras
`;

const StyledText1 = styled.h1`
  font-size: 1em;
  text-align: center;
  color: purple; // Un color de texto más claro
  font-family: "Roboto", sans-serif; // La fuente importada de Google Fonts
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); // Ajusta estos valores para cambiar la sombra
  letter-spacing: 0.05em; // Espaciado de letras
`;

const NosotrosSection = styled.section`
  margin: 2em auto;
  max-width: 800px;
  text-align: justify;
  color: #4a4a4a; // Un color de texto oscuro
`;

const NosotrosHeader = styled.h2`
  font-size: 2.5em;
  text-align: center;
  color: #be1c7d; // Un color de texto más brillante
  margin-bottom: 1em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); // Ajusta estos valores para cambiar la sombra
`;

const NosotrosParagraph = styled.p`
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 1em;
  color: #be1c7d; // Un color de texto oscuro
  font-family: "Roboto", sans-serif; // La fuente importada de Google Fonts
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); // Ajusta estos valores para cambiar la sombra
  letter-spacing: 0.05em; // Espaciado de letras
  transition: color 0.3s ease; // Añade transición al cambio de color

  &:hover {
    color: #4a4a4a; // Cambia el color del texto al pasar el mouse
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: space-around;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  animation: ${fadeIn} 1s ease-out forwards;
  //flex-wrap: wrap; // permite que las imágenes se muevan a la línea siguiente si no caben en la misma línea
`;

const ServicesSection = styled.section`
  margin: 2em auto;
  max-width: 98%;
  /* text-align: justify; */
  color: #4a4a4a; // Un color de texto oscuro
`;

const ServicesItem = styled.div`
  display: flow-root;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  padding: 1em;
  background-color: white; // Un color de fondo claro
  border-radius: 8px; // Bordes redondeados
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); // Una sombra suave
`;

const ServicesText = styled.p`
  font-size: 1.2em;
  line-height: 1.6;
  color: #4a4a4a; // Un color de texto oscuro
  font-family: "Roboto", sans-serif; // La fuente importada de Google Fonts
  letter-spacing: 0.05em; // Espaciado de letras
  transition: color 0.3s ease; // Añade transición al cambio de color

  &:hover {
    color: #be1c7d; // Cambia el color del texto al pasar el mouse
  }
`;

const ServicesIcon = styled.div`
  font-size: 3em;
  color: #be1c7d;
  text-align: center;
`;

const ServiceColumns = styled.div`
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
`;

const SocialMediaIconsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
`;

const SocialMediaIconsContainer1 = styled.div`
  position: absolute;
  top: 70%;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  justify-content: center;
  padding: 50px;
  z-index: 1001;
`;

const StyledIcon = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme};
  margin: 0 25px;

  &:hover {
    color: #ff69b4;
  }
`;

const ContactColumns = styled.div`
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
`;

const ImagesHeader = styled.h2`
  font-size: 2.5em;
  text-align: center;
  color: #be1c7d; // Un color de texto más brillante
  margin-bottom: -2em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); // Ajusta estos valores para cambiar la sombra
`;

// Estilos para el contenedor del modal
const customStyles = {
  content: {
    position: "relative",
    margin: "auto 25% auto auto",
    width: "80%", // ajusta este valor para cambiar el tamaño del modal
    maxWidth: "800px", // ajusta este valor para establecer un ancho máximo
    background: "#fff",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    boxShadow: "0px 10px 30px -5px rgba(0, 0, 0, 0.3)",
    boxSizing: "border-box",
    overflow: "auto",
  },
};

// Estilos para el botón de cerrar
const closeButtonStyles = {
  position: "absolute",
  top: "10px",
  right: "10px",
  background: "none",
  border: "none",
  fontSize: "20px",
  cursor: "pointer",
};

const imageStylesVertical = {
  maxHeight: "90vh", // limita la altura al 90% de la altura de la ventana
  width: "auto", // para mantener la proporción de la imagen
  objectFit: "contain", // para mantener las proporciones de la imagen al redimensionar
};

const imageStylesHorizontal = {
  width: "100%",
  height: "auto",
  objectFit: "contain", // para mantener las proporciones de la imagen al redimensionar
};

const ContactSection = styled.section`
  padding: 2em;
  background-color: rgba(190, 28, 125, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 600px;
`;

const FormInput = styled.input`
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 1em;
  padding: 1em;
  font-size: 1em;
`;

const FormTextArea = styled.textarea`
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 1em;
  padding: 1em;
  font-size: 1em;
`;

const SubmitButton = styled.button`
  background-color: #be1c7d;
  color: white;
  padding: 1em;
  border-radius: 5px;
  border: none;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #9e1c5d;
  }
`;

const ContactInfo = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #e0f7fa;
  padding: 20px;
  border-radius: 10px;
`;

const ContactHeader = styled.h3`
  font-size: 24px;
  color: #006064;
`;

const ContactDetail = styled.p`
  font-size: 18px;
  color: #004d40;
`;

const ContactLink = styled.a`
  color: #00838f;
  text-decoration: none;
`;

const ResponsiveIframe = styled.iframe`
  width: 100%;
  height: 450px;
  border: 0;

  @media (min-width: 1440px) {
    width: 100%;
    height: 450px;
  }
`;

const Navigation = styled.nav`
  padding-left: 25%;
  margin-right: 5%;
  margin-top: 21px;

  @media (max-width: 1440px) {
    padding-left: 15%;
  }

  @media (max-width: 1258px) {
    padding-left: 3%;
  }
  @media (max-width: 1100px) {
    margin-top: 30px;
    padding-left: 10%;
  }
  @media (max-width: 930px) {
    padding-left: 5%;
    margin-top: 35px;
  }
  @media (max-width: 795px) {
    padding-left: 0%;
  }
`; /*aquiuiuioiiiiiiiiiiiiiiiiii vooooy*/

const StyledLink = styled(Link)`
  color: ${(props) =>
    props.hoverIndex === props.index
      ? props.index % 2 === 0
        ? "rgba(190,28,125,1)"
        : "rgba(56,182,255,1)"
      : props.index % 2 === 0
      ? "White"
      : "White"};
  background-color: ${(props) =>
    props.hoverIndex === props.index
      ? props.index % 2 === 0
        ? "rgba(190,28,125,.5)"
        : "rgba(56,182,255,.5)"
      : props.index % 2 === 0
      ? "#be1c7d"
      : "rgba(56,182,255,1)"};
  text-decoration: none;
  padding: 30px 50px 10px 50px;
  border-radius: 40px 10px 0 0;
  transition: 0.3s;
  font-size: 20px;

  @media (max-width: 1100px) {
    padding: 15px 25px 5px 25px;
  }
  @media (max-width: 930px) {
    font-size: 0.9rem;
  }
`;

/*********************************************MOBILE VIEW************************************* */

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media (min-width: 819px) {
    display: none;
  }
`;
// ? "rgba(190,28,125,1)"
// : "rgba(56,182,255,1)"
const Header = styled.header`
  background-color: rgba(190, 28, 125, 1);
  color: #fff;
  padding: 1px 0;
  text-align: center;
  position: relative;

  h1 {
    margin: 0;
  }
`;

const HamburgerMenu = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  display: none;
  position: fixed;
  z-index: 1000;
  color: yellow;
  background-color: rgba(56, 182, 255, 0.4);

  @media (max-width: 819px) {
    display: block;
  }
`;

const NavMobile = styled.nav`
  background-color: rgba(190, 28, 125, 1);
  position: absolute; // Hacemos que el menú sea absolutamente posicionado
  top: 100%; // Inicia justo debajo del encabezado
  left: 0; // Alineado a la izquierda
  right: 0; // Alineado a la derecha
  z-index: 1; // Asegurarse de que aparezca encima de otros elementos
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // Una sombra sutil, también opcional
`;

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const NavItem = styled.li`
  text-align: center;
  padding: 10px;
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

const Main = styled.main`
  flex: 1;
  padding: 20px;
`;

const Footer = styled.footer`
  background-color: #333;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  background-color: rgba(190, 28, 125, 1);
`;

const FooterText = styled.p`
  font-size: 10px; // Ajusta el tamaño a tu preferencia
  text-align: center;
  margin: 0;
`;

const SectionWrapper = styled.section`
  padding: 20px;
  background-color: #f4f4f4; // Un color de fondo suave para diferenciar la sección
  margin-top: 20px; // Espaciado superior para separarlo de otros elementos
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  color: rgba(56, 182, 255, 1);
  text-align: center;
  margin-bottom: 20px; // Espaciado debajo del título
`;

const SectionContent = styled.p`
  font-size: 16px;
  color: rgba(190, 28, 125, 1);
  line-height: 1.5;
  text-align: justify;
`;

const ServicesSection1 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 15px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin: 20px 0;
`;

const ServicesTitle = styled.h2`
  text-align: center;
  color: rgba(190, 28, 125, 1);
  margin-bottom: 20px;
  width: 100%;
`;

const ServiceList = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
  text-align: center;
`;

const ServiceItem = styled.li`
  margin: 10px 0;
  font-size: 1.1em;
  color: rgba(56, 182, 255, 1);
`;

const AdditionalInfo = styled.p`
  margin: 20px 0;
  color: rgba(190, 28, 125, 0.8);
  text-align: center;
  width: 100%;
`;

const InstagramIconContainer = styled.a`
  position: fixed;
  left: 15px;
  bottom: 15px;
  background-color: #e4405f; // Color oficial de Instagram
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const WhatsappIconContainer = styled.a`
  position: fixed;
  right: 15px;
  bottom: 15px;
  background-color: #25d366; // Color de fondo de WhatsApp
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const FooterLink = styled.a`
  color: inherit; // para heredar el color del texto del componente padre
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const GalleryButton = styled.button`
  display: block;
  margin: 20px auto; // Centra el botón horizontalmente
  padding: 10px 20px;
  background-color: #dd2a7b; // Puedes ajustar el color como desees
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c41a6b; // Un color más oscuro para el efecto hover
  }

  @media (min-width: 426px) {
    display: none; // Oculta el botón en pantallas grandes
  }
`;

const GalleryContainer = styled.section`
  display: ${(props) => (props.isVisible ? "block" : "none")};

  @media (min-width: 426px) {
    display: block;
  }
`;

function App() {
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [headerImage, setHeaderImage] = useState(header);
  const [photos, setPhotos] = useState([]);
  const [headerOpacity, setHeaderOpacity] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showImageContainer, setShowImageContainer] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isImageVertical, setIsImageVertical] = useState(false);
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [telefono, setTelefono] = useState("");
  const [buttonColor, setButtonColor] = useState("");
  const [buttonMessage, setButtonMessage] = useState("Enviar mensaje");
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const [showGallery, setShowGallery] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
    if (!menuOpen) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Si no se hizo click en el menú y tampoco en el botón de hamburguesa
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };

    // Agregamos el event listener al document
    document.addEventListener("mousedown", handleClickOutside);

    // Limpiamos el event listener cuando el componente se desmonta
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const enviarCorreo = async (e) => {
    e.preventDefault();

    if (!nombre || !correo || !mensaje || !telefono) {
      setButtonColor("red");
      setButtonMessage("Todos los campos son obligatorios");
      return;
    }

    const data = {
      nombre,
      correo,
      mensaje,
      telefono,
    };

    try {
      const response = await axios.post(
        "https://www.lospequesgdl.com/enviar.php",
        data
      );

      if (response.data.status === "success") {
        setButtonColor("green");
        setButtonMessage("Correo enviado exitosamente");
        setNombre("");
        setCorreo("");
        setTelefono("");
        setMensaje("");
      } else {
        setButtonColor("red");
        setButtonMessage("Hubo un error al enviar el correo");
      }
    } catch (error) {
      console.error(error);
      setButtonColor("red");
      setButtonMessage("Hubo un error al enviar el correo");
    }
  };

  useEffect(() => {
    if (buttonColor !== "") {
      const timer = setTimeout(() => {
        setButtonColor("");
        setButtonMessage("Enviar mensaje");
      }, 5000);
      return () => clearTimeout(timer); // Esto es para limpiar el temporizador si el componente se desmonta antes de que pasen los 5 segundos
    }
  }, [buttonColor]);

  const handleImageLoad = ({ target: img }) => {
    setIsImageVertical(img.naturalHeight > img.naturalWidth);
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      // console.log(`Scroll position: ${position}`); // Console log de la posición del scroll
      setScrollPosition(position);
      if (position >= 200) {
        setShowImageContainer(true);
      } else {
        setShowImageContainer(false);
      }
      // if (position <= 50) {
      //   setHeaderImage(header);
      //   // document.body.style.overflow = 'hidden';
      // } else if (position > 50 && position <= 150) {
      //   setHeaderImage(header2);
      //   // document.body.style.overflow = "unset";
      // } else {
      //   setHeaderImage(header3);
      // }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    axios
      .get("https://www.lospequesgdl.com/api.php", {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
        },
      })
      .then((res) => {
        setPhotos(
          res.data.map((url) => ({
            src: url,
            // Si tu API no devuelve el ancho y el alto, puedes establecerlos tú mismo.
            // O podrías modificar tu componente para que no los necesite.
            width: 1,
            height: 1,
          }))
        );
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    axios
      .get("https://api.unsplash.com/photos", {
        headers: {
          Authorization: "Client-ID password",
        },
        params: {
          per_page: 30,
        },
      })
      .then((res) => {
        setPhotos(
          res.data.map((photo) => ({
            src: photo.urls.small,
            width: photo.width,
            height: photo.height,
          }))
        );
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      if (position > 0) {
        setHeaderOpacity(0.3); // Ajusta el valor a la transparencia que prefieras
      } else {
        setHeaderOpacity(1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="App"
      style={{
        backgroundColor: "rgba(190,28,125,.1)",
      }}
    >
      <GlobalStyle />
      <GlobalStarStyle />
      <StarBackground />
      <Header1>
        {!modalIsOpen && (
          <header
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "absolute", // Cambiado a "absolute"
              width: "100%",
              zIndex: 100,
              padding: "10px 0",
              opacity: headerOpacity,
            }}
            onMouseOver={() => setHeaderOpacity(1)} // Restauramos la opacidad al pasar el mouse
            onMouseOut={() => window.pageYOffset > 0 && setHeaderOpacity(0.3)} // Reducimos la opacidad al sacar el mouse, pero solo si se ha hecho scroll
          >
            <SocialMediaIconsContainer>
              <StyledText className={loaded ? "slideFromRight" : ""}>
                <StyledIcon
                  href="https://www.instagram.com/lospequesgdl/"
                  target="_blank"
                  rel="noopener noreferrer"
                  theme="#DD2A7B"
                >
                  <FaInstagram size={32} />
                </StyledIcon>
                <StyledIcon
                  href="https://www.facebook.com/lospequesgdl"
                  target="_blank"
                  rel="noopener noreferrer"
                  theme="rgb(56, 182, 255)"
                >
                  <FaFacebook size={32} />
                </StyledIcon>
                <StyledIcon
                  href="https://bit.ly/3xEN7zS"
                  target="_blank"
                  rel="noopener noreferrer"
                  theme="#008000"
                >
                  <FaWhatsapp size={32} />
                </StyledIcon>
              </StyledText>
            </SocialMediaIconsContainer>
            <div
              style={{
                backgroundColor: "rgba(255,105,180,0.3)",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Router>
                <div>
                  <Link to="/">
                    <img
                      src={logo}
                      alt="Logo Los Peques GDL"
                      style={{ height: "250px", marginLeft: "20px" }}
                      className={loaded ? "slideDown" : ""}
                    />
                  </Link>
                </div>
                <Navigation>
                  <ul
                    style={{
                      listStyle: "none",
                      padding: "0",
                      margin: "0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {[
                      "Inicio",
                      "Nosotros",
                      "Servicios",
                      "Galería",
                      "Contacto",
                    ].map((link, index) => (
                      <li
                        key={index}
                        className={loaded ? "slideDown" : ""}
                        style={{
                          marginRight: index < 4 ? "1px" : "0",
                          animationDelay: `${index * 0.2}s`,
                        }}
                      >
                        <StyledLink
                          to={link}
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          onMouseEnter={() => setHoverIndex(index)}
                          onMouseLeave={() => setHoverIndex(-1)}
                          hoverIndex={hoverIndex}
                          index={index}
                        >
                          {link}
                        </StyledLink>
                      </li>
                    ))}
                  </ul>
                </Navigation>
              </Router>
            </div>
          </header>
        )}
        <div
          style={{
            backgroundColor: "rgba(190,28,125,.1)",
            padding: "0 calc((100vw - 1920px) / 2)",
          }}
        >
          <div id="Inicio" style={{ paddingTop: "168px" }}>
            <div
              style={{
                backgroundImage: `url(${headerImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                width: "100%",
                height: "550px",
                display: "flex", // añadir
                flexDirection: "column", // añadir
                justifyContent: "flex-end", // añadir
              }}
            >
              <div style={{ position: "relative", padding: "40px" }}>
                <StyledText className={loaded ? "slideFromRight" : ""}>
                  Cuidamos lo más importante
                </StyledText>
              </div>
            </div>
          </div>
          <main>
            {/* {scrollPosition > 200 && ( */}
            <section id="Contacto">
              {
                <div>
                  <ContactColumns>
                    <img
                      src={feed4}
                      alt="niño leyendo"
                      style={{ width: "100%", height: "auto" }}
                      className={"slideUp2"}
                    />
                    <ImagesHeader>Contacto</ImagesHeader>
                    <br />
                    <ContactSection>
                      <StyledText>¡Nos encantaría escuchar de ti!</StyledText>
                      <ContactForm onSubmit={enviarCorreo}>
                        <FormInput
                          type="text"
                          placeholder="Tu nombre"
                          onChange={(e) => setNombre(e.target.value)}
                          value={nombre}
                          required
                        />
                        <FormInput
                          type="email"
                          placeholder="Tu correo electrónico"
                          onChange={(e) => setCorreo(e.target.value)}
                          value={correo}
                          required
                        />
                        <FormInput
                          type="tel"
                          placeholder="Tu teléfono"
                          onChange={(e) => setTelefono(e.target.value)}
                          value={telefono}
                          required
                        />
                        <FormTextArea
                          placeholder="Tu mensaje"
                          onChange={(e) => setMensaje(e.target.value)}
                          value={mensaje}
                          required
                        />
                        {buttonColor === "green" ? (
                          <Gracias />
                        ) : (
                          <SubmitButton
                            style={{ backgroundColor: buttonColor }}
                            type="submit"
                            onClick={enviarCorreo} // Asegúrate de llamar la función enviarCorreo en el onClick
                          >
                            {buttonMessage}
                          </SubmitButton>
                        )}
                      </ContactForm>
                    </ContactSection>
                    <img
                      src={feed3}
                      alt="niño leyendo"
                      style={{ width: "100%", height: "auto" }}
                      className={"slideUp2"}
                    />
                  </ContactColumns>
                </div>
              }
            </section>
            <ImageContainer show={showImageContainer} style={{ zIndex: 1 }}>
              <img
                src={feed1}
                alt="feed 1"
                style={{ width: "30%", height: "auto" }}
                className={"slideUp2"}
              />
              <div
                style={{
                  flexBasis: "60%",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                }}
              >
                <NosotrosSection id="Nosotros">
                  <NosotrosHeader className={""}>
                    ¿Quiénes somos?
                  </NosotrosHeader>
                  <NosotrosParagraph className={""}>
                    Somos una institución privada con más de 16 años en el
                    sector de cuidado infantil brindando confianza, seguridad y
                    tranquilidad a padres y madres que trabajan.
                  </NosotrosParagraph>

                  <NosotrosParagraph className={""}>
                    Nuestra finalidad es asegurarte que tus peques están en las
                    mejores manos de Guadalajara, ofreciéndoles educación de
                    calidad con educadoras capacitadas y un plan de estudios
                    adaptado a cada etapa.
                  </NosotrosParagraph>

                  <NosotrosParagraph className={""}>
                    Sabemos lo importante que son tus peques para ti, por eso,
                    nuestro enfoque es brindar el mayor cariño, cuidado y
                    educación para que tus hijos se sientan como en casa.
                  </NosotrosParagraph>

                  <NosotrosParagraph className={""}>
                    ¡Cuidamos lo que más quieres! Si buscas un lugar con
                    ambiente sano y educativo, entonces somos tu mejor opción.
                  </NosotrosParagraph>
                </NosotrosSection>
              </div>
              <img
                src={feed2}
                alt="feed 2"
                style={{ width: "30%", height: "auto" }}
                className={"slideUp2"}
              />
            </ImageContainer>
            {/* )} */}
            {/* {scrollPosition > 500 && ( */}
            <ServiceColumns>
              <img
                src={reed1}
                alt="niño leyendo"
                style={{ width: "100%", height: "auto" }}
                className={"slideUp2"}
              />
              <ServicesSection id="Servicios">
                <NosotrosHeader className={"slideDown"}>
                  <br></br>Nuestros Servicios
                </NosotrosHeader>
                <ServicesItem className={"slideUp1"}>
                  <ServicesIcon>
                    <FaStar /> Guardería <FaStar />
                  </ServicesIcon>
                </ServicesItem>
                <ServicesItem className={"slideUp2"}>
                  <ServicesIcon>
                    <FaStar /> Preescolar <FaStar />
                  </ServicesIcon>
                </ServicesItem>
                <ServicesItem className={"slideUp1"}>
                  <ServicesIcon>
                    <FaStar /> Cuidado por horas <FaStar />
                  </ServicesIcon>
                  <ServicesText>
                    <ServiceColumns>
                      <br></br>
                      <ul>
                        Horarios: Lunes a Viernes 8:00 am a 4:00 pm / 7:00 am a
                        5:00 pm
                        <li>Ludoteca y áreas de juegos</li>
                        <li>Actividades recreativas durante la estancia</li>
                        <li>Alimentos incluidos</li>
                      </ul>
                    </ServiceColumns>
                  </ServicesText>
                </ServicesItem>
                <ServicesItem className={"slideUp2"}>
                  <ServicesIcon>
                    <FaStar /> Cursos de verano <FaStar />
                  </ServicesIcon>
                  <ServicesText>
                    <ServiceColumns>
                      <ul>
                        <li>Manualidades</li>
                        <li>Club de lectura</li>
                        <li>Clases de teatro</li>
                        <li>Taller de Cocina</li>
                        <li>Danza</li>
                      </ul>
                    </ServiceColumns>
                  </ServicesText>
                </ServicesItem>
              </ServicesSection>
            </ServiceColumns>
            {/* )} */}
            {/* {scrollPosition > 1450 && ( */}
            <section id="Galería">
              <ImagesHeader className={"slideUp2"}>Galería</ImagesHeader>
              <PhotoGallery
                className={"slideUp2"}
                photos={photos}
                onClick={(event, { photo, index }) => {
                  setCurrentImage(photo);
                  setModalIsOpen(true);
                }}
              />
              <Modal
                style={customStyles}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
              >
                {currentImage && (
                  <>
                    <img
                      src={currentImage.src}
                      style={
                        isImageVertical
                          ? imageStylesVertical
                          : imageStylesHorizontal
                      }
                      alt={currentImage.alt}
                      onLoad={handleImageLoad}
                    />
                    <button
                      style={closeButtonStyles}
                      onClick={() => setModalIsOpen(false)}
                    >
                      <FaTimes />
                    </button>
                  </>
                )}
              </Modal>
              <ContactColumns>
                <ResponsiveIframe
                  title="losPeques"
                  src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d3733.351030015796!2d-103.3930221350736!3d20.655293036201865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x8428add99c1ef70b%3A0xf0f70e48cae94f33!2sC.%20Cenit%201216%2C%20Jardines%20del%20Bosque%2C%2044520%20Guadalajara%2C%20Jal.!3m2!1d20.6575305!2d-103.39079369999999!4m5!1s0x8428addaf761b92d%3A0x2f0e04ac2a4f3e36!2sExpo%20Guadalajara%2C%20Av.%20Mariano%20Otero%201499%2C%20Verde%20Valle%2C%2044550%20Guadalajara%2C%20Jal.!3m2!1d20.6530556!2d-103.3913889!5e0!3m2!1ses-419!2smx!4v1688535788758!5m2!1ses-419!2smx"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></ResponsiveIframe>
                <br />
                <br />
                <br />
                <br />
                <img
                  src={feed5}
                  alt="niño leyendo"
                  style={{ width: "100%", height: "auto" }}
                  className={"slideUp2"}
                />
                <ContactInfo>
                  <ContactHeader>
                    Agenda una cita para conocer nuestras instalaciones
                  </ContactHeader>

                  <ContactDetail>
                    <strong>Teléfono:</strong> 3315256315
                  </ContactDetail>

                  <ContactDetail>
                    <strong>Celular:</strong> 3311625057
                  </ContactDetail>

                  <ContactHeader>Dirección</ContactHeader>

                  <ContactDetail>
                    Cenit 1216, Jardines del Bosque, a 2 cuadras de la expo GDL
                  </ContactDetail>

                  <ContactHeader>Correo</ContactHeader>

                  <ContactDetail>
                    <ContactLink href="mailto:lospequesgld2@gmail.com">
                      lospequesgld2@gmail.com
                    </ContactLink>
                  </ContactDetail>
                </ContactInfo>
              </ContactColumns>
            </section>
            {/* )} */}
          </main>
          <WhatsappIconContainer href="https://bit.ly/3xEN7zS" target="_blank">
            <FaWhatsapp size={64} color="white" />
          </WhatsappIconContainer>
        </div>
        <footer
          style={{
            backgroundColor: "rgba(190,28,125,.1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <StyledIcon
              href="https://www.instagram.com/lospequesgdl/"
              target="_blank"
              rel="noopener noreferrer"
              theme="#DD2A7B"
            >
              <FaInstagram size={32} />
            </StyledIcon>
            <StyledIcon
              href="https://www.facebook.com/lospequesgdl"
              target="_blank"
              rel="noopener noreferrer"
              theme="rgb(56, 182, 255)"
            >
              <FaFacebook size={32} />
            </StyledIcon>
            <StyledIcon
              href="https://bit.ly/3xEN7zS"
              target="_blank"
              rel="noopener noreferrer"
              theme="#008000"
            >
              <FaWhatsapp size={32} />
            </StyledIcon>
          </div>
          <StyledIcon
            href="https://www.lospequesgdl.com/privacidad.html"
            target="_blank"
            rel="noopener noreferrer"
            theme="#DD2A7B"
          >
            Derechos reservados © {new Date().getFullYear()} Los Peques GDL
          </StyledIcon>
        </footer>
      </Header1>
      <AppWrapper>
        <Header>
          <img
            src={logo}
            alt="Logo Los Peques GDL"
            style={{ height: "85px" }}
            className={loaded ? "slideDown" : ""}
          />
          <StyledText1 className={loaded ? "slideFromRight" : ""} id="#inicio1">
            Cuidamos lo más importante
          </StyledText1>
          {!menuOpen && (
            <SocialMediaIconsContainer1
              className={loaded ? "slideFromRight" : ""}
            >
              <StyledIcon
                href="https://www.instagram.com/lospequesgdl/"
                target="_blank"
                rel="noopener noreferrer"
                theme="#DD2A7B"
              >
                <FaInstagram size={15} />
              </StyledIcon>
              <StyledIcon
                href="https://www.facebook.com/lospequesgdl"
                target="_blank"
                rel="noopener noreferrer"
                theme="rgb(56, 182, 255)"
              >
                <FaFacebook size={15} />
              </StyledIcon>
              <StyledIcon
                href="https://bit.ly/3xEN7zS"
                target="_blank"
                rel="noopener noreferrer"
                theme="#008000"
              >
                <FaWhatsapp size={15} />
              </StyledIcon>
            </SocialMediaIconsContainer1>
          )}
          <HamburgerMenu onClick={toggleMenu} ref={hamburgerRef}>
            ☰
          </HamburgerMenu>
          {menuOpen && (
            <NavMobile ref={menuRef}>
              <NavList>
                {["Inicio", "Nosotros", "Servicios", "Galería", "Contacto"].map(
                  (link) => (
                    <NavItem key={link}>
                      <NavLink href={`#${link}3`} onClick={toggleMenu}>
                        {link}
                      </NavLink>
                    </NavItem>
                  )
                )}
              </NavList>
            </NavMobile>
          )}
        </Header>
        <Main>
          <section id="Inicio3">
            <img
              src={headerImage}
              alt="Los Peques GDL Portada"
              style={{ maxWidth: "100%" }}
              className={loaded ? "slideDown" : ""}
            />
          </section>
          <SectionWrapper>
            <SectionTitle className={"slideUp2"} id="#Nosotros3">
              ¿Quiénes somos?
            </SectionTitle>
            <SectionContent className={"slideUp2"}>
              Con más de 16 años en cuidado infantil, somos una institución
              privada en Guadalajara dedicada a ofrecer confianza y seguridad a
              los padres trabajadores. Nuestro compromiso es proporcionar
              educación de calidad con un plan adaptado a cada etapa.
              Priorizamos el cariño y el cuidado, asegurando un ambiente sano y
              educativo para que los niños se sientan como en casa.
            </SectionContent>
          </SectionWrapper>
          {scrollPosition > 500 && (
            <ServicesSection1 id="Servicios3" className={"slideUp1"}>
              <ServicesTitle>Nuestros Servicios</ServicesTitle>

              <ServiceList>
                <ServiceItem>
                  <FaStar /> Guardería <FaStar />
                </ServiceItem>
                <ServiceItem>
                  <FaStar /> Preescolar <FaStar />
                </ServiceItem>
                <ServiceItem>
                  <FaStar /> Cuidado por horas <FaStar />
                </ServiceItem>
              </ServiceList>

              <AdditionalInfo>
                Horarios: Lunes a Viernes 8:00 am a 4:00 pm / 7:00 am a 5:00 pm
              </AdditionalInfo>
              <AdditionalInfo>Ludoteca y áreas de juegos</AdditionalInfo>
              <AdditionalInfo>
                Actividades recreativas durante la estancia
              </AdditionalInfo>
              <AdditionalInfo>Alimentos incluidos</AdditionalInfo>

              <ServiceList>
                <ServiceItem>
                  <FaStar /> Cursos de verano <FaStar />
                </ServiceItem>
                <AdditionalInfo>Manualidades</AdditionalInfo>
                <AdditionalInfo>Club de lectura</AdditionalInfo>
                <AdditionalInfo>Clases de teatro</AdditionalInfo>
                <AdditionalInfo>Taller de Cocina</AdditionalInfo>
                <AdditionalInfo>Danza</AdditionalInfo>
              </ServiceList>
            </ServicesSection1>
          )}
          <section>
            <GalleryButton
              id="Galería3"
              onClick={() => setShowGallery(!showGallery)}
            >
              Galería
            </GalleryButton>
            <GalleryContainer isVisible={showGallery}>
              <PhotoGallery
                className={"slideUp2"}
                photos={photos}
                onClick={(event, { photo, index }) => {
                  setCurrentImage(photo);
                  setModalIsOpen(true);
                }}
              />
            </GalleryContainer>
            {/* No he eliminado la modal por si aún la necesitas para otra cosa. */}
            <Modal
              style={customStyles}
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)}
            >
              {currentImage && (
                <>
                  <img
                    src={currentImage.src}
                    style={
                      isImageVertical
                        ? imageStylesVertical
                        : imageStylesHorizontal
                    }
                    alt={currentImage.alt}
                    onLoad={handleImageLoad}
                  />
                  <button
                    style={closeButtonStyles}
                    onClick={() => setModalIsOpen(false)}
                  >
                    <FaTimes />
                  </button>
                </>
              )}
            </Modal>
          </section>
          <ImagesHeader>Contacto</ImagesHeader>
          <br />
          <ContactSection id="Contacto3">
            <ServicesTitle>¡Nos encantaría escuchar de ti!</ServicesTitle>
            <ContactForm onSubmit={enviarCorreo}>
              <FormInput
                type="text"
                placeholder="Tu nombre"
                onChange={(e) => setNombre(e.target.value)}
                value={nombre}
              />
              <FormInput
                type="email"
                placeholder="Tu correo electrónico"
                onChange={(e) => setCorreo(e.target.value)}
                value={correo}
              />
              <FormInput
                type="tel"
                placeholder="Tu teléfono"
                onChange={(e) => setTelefono(e.target.value)}
                value={telefono}
                required
              />
              <FormTextArea
                placeholder="Tu mensaje"
                onChange={(e) => setMensaje(e.target.value)}
                value={mensaje}
              />
              <SubmitButton
                style={{ backgroundColor: buttonColor }}
                type="submit"
              >
                {buttonMessage}
              </SubmitButton>
            </ContactForm>
          </ContactSection>
          <ContactInfo>
            <ContactHeader>
              Agenda una cita para conocer nuestras instalaciones
            </ContactHeader>

            <ContactDetail>
              <strong>Teléfono:</strong> 3315256315
            </ContactDetail>

            <ContactDetail>
              <strong>Celular:</strong> 3311625057
            </ContactDetail>

            <ContactHeader>Dirección</ContactHeader>

            <ContactDetail>
              Cenit 1216, Jardines del Bosque, a 2 cuadras de la expo GDL
            </ContactDetail>

            <ContactHeader>Correo</ContactHeader>

            <ContactDetail>
              <ContactLink href="mailto:lospequesgld2@gmail.com">
                lospequesgld2@gmail.com
              </ContactLink>
            </ContactDetail>
          </ContactInfo>
          <br />
          <br />
          <ResponsiveIframe
            title="losPeques"
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d3733.351030015796!2d-103.3930221350736!3d20.655293036201865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x8428add99c1ef70b%3A0xf0f70e48cae94f33!2sC.%20Cenit%201216%2C%20Jardines%20del%20Bosque%2C%2044520%20Guadalajara%2C%20Jal.!3m2!1d20.6575305!2d-103.39079369999999!4m5!1s0x8428addaf761b92d%3A0x2f0e04ac2a4f3e36!2sExpo%20Guadalajara%2C%20Av.%20Mariano%20Otero%201499%2C%20Verde%20Valle%2C%2044550%20Guadalajara%2C%20Jal.!3m2!1d20.6530556!2d-103.3913889!5e0!3m2!1ses-419!2smx!4v1688535788758!5m2!1ses-419!2smx"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></ResponsiveIframe>
        </Main>
        <Footer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <StyledIcon
              href="https://www.instagram.com/lospequesgdl/"
              target="_blank"
              rel="noopener noreferrer"
              theme="#DD2A7B"
            >
              <FaInstagram size={21} />
            </StyledIcon>
            <StyledIcon
              href="https://www.facebook.com/lospequesgdl"
              target="_blank"
              rel="noopener noreferrer"
              theme="rgb(56, 182, 255)"
            >
              <FaFacebook size={21} />
            </StyledIcon>
            <StyledIcon
              href="https://bit.ly/3xEN7zS"
              target="_blank"
              rel="noopener noreferrer"
              theme="#008000"
            >
              <FaWhatsapp size={21} />
            </StyledIcon>
          </div>
          <FooterText>
            Derechos reservados © {new Date().getFullYear()}
            <FooterLink
              href="https://www.lospequesgdl.com/privacidad.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Los Peques GDL
            </FooterLink>
          </FooterText>
        </Footer>
        <WhatsappIconContainer href="https://bit.ly/3xEN7zS" target="_blank">
          <FaWhatsapp size={32} color="white" />
        </WhatsappIconContainer>
        <InstagramIconContainer href="tel:3315256315">
          <FaPhoneAlt size={32} color="white" />
        </InstagramIconContainer>
      </AppWrapper>
    </div>
  );
}

export default App;
