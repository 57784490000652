import React from 'react';
import styled from 'styled-components';

// Estilos mejorados para el mensaje de agradecimiento
const GraciasSection = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const GraciasMensaje = styled.p`
  font-size: 24px;
  color: #333;
  font-weight: bold; /* Agregado para resaltar el mensaje */
  font-family: Arial, sans-serif; /* Cambiado el tipo de fuente para mejorar la legibilidad */
  line-height: 1.5; /* Ajuste de la altura de línea para una mejor presentación */
`;

const Gracias = () => {
  return (
    <GraciasSection>
      <GraciasMensaje>¡Gracias por ponerte en contacto con nosotros! Estamos emocionados por hablar contigo.</GraciasMensaje>
    </GraciasSection>
  );
};

export default Gracias;
